<template>
  <div id="ProductDetails" v-loading="loading" style="min-height: 100%">
    <div>
      <HomePageTop ref="pagetop"></HomePageTop>
      <!-- {{details}} -->
      <Dialog ref="dialog" :config="config">
        <!-- <span>插槽内容</span> -->
      </Dialog>
      <!-- {{ details }} -->
      <div class="main" v-if="details != ''">
        <!-- 商品规格 -->

        <div class="contentBackground clearfloat">
          <div class="content">
            <div class="clearfloat detailTop">
              <h3>HOME>{{ details.title }}</h3>
              <div class="detaiCon">
                <div class="conLeft">
                  <!-- 图片说明 -->
                  <div class="fl image clearfix clearfloat">
                    <!-- 数据请求回来显示 -->
                    <div class="pic-box fl">
                      <video v-if="showType == 'video'" class="bigVideo" muted autoplay controls loop>
                        <source :src="details.video" type="video/mp4" />
                        <source :src="details.video" type="video/ogg" />
                        您的浏览器不支持 video 标签。
                      </video>
                      <pic-zoom v-if="showType == 'img'" :url="detailsImg" :scale="2"></pic-zoom>
                    </div>

                    <!-- 图片详情下列图片 -->
                    <div class="img-box">
                      <!-- 左箭头 -->
                      <div class="leftgo" @click="leftarrow()">
                        <div class="icon">
                          <i class="el-icon-arrow-left"></i>
                        </div>
                      </div>
                      <!-- 右箭头 -->
                      <div class="rightgo" @click="rightarrow()">
                        <div class="icon">
                          <i class="el-icon-arrow-right"></i>
                        </div>
                      </div>
                      <!-- 图片列表 -->
                      <div class="list">
                        <div class="images">
                          <div class="video" v-if="details.video" @click="
                            showType = 'video'
                          detailsImgIndex = -1
                            ">
                            <div class="videoIcon" @click="showType = 'video'">
                              <!-- <i class="el-icon-video-play"></i> -->
                              <svg t="1678259841461" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                xmlns="http://www.w3.org/2000/svg" p-id="2732" width="40" height="40">
                                <path
                                  d="M512 0C230.4 0 0 230.4 0 512s230.4 512 512 512 512-230.4 512-512S793.6 0 512 0z m0 981.333333C253.866667 981.333333 42.666667 770.133333 42.666667 512S253.866667 42.666667 512 42.666667s469.333333 211.2 469.333333 469.333333-211.2 469.333333-469.333333 469.333333z"
                                  fill="#ffffff" p-id="2733"></path>
                                <path
                                  d="M672 441.6l-170.666667-113.066667c-57.6-38.4-106.666667-12.8-106.666666 57.6v256c0 70.4 46.933333 96 106.666666 57.6l170.666667-113.066666c57.6-42.666667 57.6-106.666667 0-145.066667z"
                                  fill="#ffffff" p-id="2734"></path>
                              </svg>
                            </div>
                            <video :class="{
                              detailsImgIndex: showType == 'video',
                            }">
                              <source :src="details.video" type="video/mp4" />
                              <source :src="details.video" type="video/ogg" />
                              您的浏览器不支持 video 标签。
                            </video>
                          </div>
                          <div class="img" v-for="(item, index) in details.images" :key="item">
                            <img :src="item" alt="" @click="
                              getPIc(item, index)
                            showType = 'img'
                              " :class="{
                                detailsImgIndex: detailsImgIndex == index,
                              }" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="huoyuan">
                      <a href="javascript:;" @click="favoriteGoodsAdd()">
                        <div class="inco">
                          <img v-if="goodsFavoriteStatus" src="../../assets/newImg/order/collection2.svg" alt="" />
                          <img v-else src="../../assets/newImg/order/collection1.svg" alt="" />
                        </div>

                        {{ $fanyi('收藏商品') }}
                      </a>
                      <!-- 1688商品详情 -->
                      <span class="imgbox1688">
                        <span>Source link></span>
                        <a :href="'https://detail.1688.com/offer/' +
                          $route.query.goods_id +
                          '.html'
                          " target="_blank">
                          <img src="../../assets/newImg/order/1688icon.svg" alt="" /></a>
                      </span>
                    </div>
                  </div>
                  <div></div>
                </div>
                <div class="conRight">
                  <div class="conRight-head">
                    <h4>
                      {{ details.title }}
                    </h4>
                    <!-- 数组 -->
                    <div class="price-range" v-if="Array.isArray(details.goodsInfo.priceRanges)">
                      <!-- 价格区间 -->
                      <!-- 价格 -->
                      <div class="row">
                        <!-- <div class="label">
                          {{ $fanyi('价格') }}
                        </div> -->
                        <div class="value">
                          <div class="priceConOpt" v-for="(ote, oin) in goodsInfo.priceRanges" :key="oin">
                            <span class="price">$
                              {{
                                $fun.RMBNumSegmentation(
                                  $fun.ceil(
                                    ote.price * $store.state.exchangeRate
                                  )
                                )
                              }}</span>
                            (
                            <span>{{ $fun.EURNumSegmentation(ote.price) }}</span>￥)
                          </div>
                        </div>
                      </div>
                      <!-- 数量 -->
                      <div class="row">
                        <!-- <div class="label">
                          {{ $fanyi('数量') }}
                        </div> -->
                        <div class="value">
                          <div class="priceConOpt" v-for="(ote, oin) in goodsInfo.priceRanges" :key="oin">
                            <span class="nums">Start with {{ ote.startQuantity }} pieces
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- 最小价格到最大价格 对象 -->
                    <div class="price-range" v-else>
                      <!-- 价格 -->
                      <div class="row">
                        <!-- <div class="label">
                          {{ $fanyi('价格') }}
                        </div>kind -->
                        <div class="value">
                          <div class="priceConOptobj">
                            <span class="price">$
                              {{
                                $fun.RMBNumSegmentation(
                                  $fun.ceil(
                                    goodsInfo.priceRanges.priceMin *
                                    $store.state.exchangeRate
                                  )
                                )
                              }}</span>
                            (
                            <span class="renminbi">{{
                              $fun.EURNumSegmentation(
                                goodsInfo.priceRanges.priceMin
                              )
                            }}</span>￥)
                          </div>
                          <div class="bolang">~</div>
                          <div class="priceConOptobj">
                            <span class="price">$
                              {{
                                $fun.RMBNumSegmentation(
                                  $fun.ceil(
                                    goodsInfo.priceRanges.priceMax *
                                    $store.state.exchangeRate
                                  )
                                )
                              }}</span>
                            (
                            <span class="renminbi">{{
                              $fun.EURNumSegmentation(
                                goodsInfo.priceRanges.priceMax
                              )
                            }}</span>￥)
                          </div>
                        </div>
                      </div>
                      <!-- 数量 -->
                      <div class="row">
                        <!-- <div class="label">
                          {{ $fanyi('数量') }}
                        </div> -->
                        <div class="value">
                          <div class="priceConOpt">
                            <span class="nums">Start with
                              {{ goodsInfo.priceRanges.startQuantity }} pieces
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <h5>
                      <i style="font-size: 16px" class="el-icon-warning"></i>
                      {{
                        $fanyi(
                          '*由于供应商活动取消等原因，商品实际的价格和rakumart平台展现的价格可能会有出入。我们将在订单报价时将最终确认的价格提交给您。'
                        )
                      }}
                    </h5>
                  </div>
                  <div class="kind">
                    <div v-for="(skuItem, skuindex) in skuProps" :key="skuindex" class="kindCon">
                      <div class="conRightTitle" :title="skuItem.key">
                        {{ $fun.trunUpperCase(skuItem.key) }}：
                      </div>

                      <div class="ConBody" v-if="skuProps.length - 1 != skuindex" id="chioseGoods">
                        <!-- <div class="controlButton" @mouseover="goLeft">
                          <i class="el-icon-arrow-left"></i>
                        </div> -->
                        <div class="sizeItem" v-for="(item, index) in skuItem.value" :key="index"
                          :class="{ choiceColor: skuItem.active == index }" @click="
                            getColor(skuItem, index)
                          showType = 'img'
                            ">
                          <img v-if="item.picUrl" :src="item.picUrl" alt="" />

                          <el-popover placement="top-start" trigger="hover">
                            <span>{{ item.name }}</span>
                            <span slot="reference"> {{ item.name }}</span>
                          </el-popover>
                        </div>
                        <!-- <div class="controlButton" @mouseover="goRight($event, true)"
                          @mouseout="goRight($event, false)">
                          <i class="el-icon-arrow-right"></i>
                        </div> -->
                      </div>
                      <!-- 多选择商品  size-->
                      <div class="allCheck" v-else>
                        <div class="allHead">
                          <div class="title" style="justify-content: center">
                            {{ $fanyi('产品') }}
                          </div>
                          <div class="price">{{ $fanyi('单价') }}</div>
                          <div class="numGroup">{{ $fanyi('在库数') }}</div>
                          <div class="num">{{ $fanyi('数量') }}</div>
                        </div>
                        <div :style="{ maxHeight: addShow ? '262px' : '10000px' }" class="list">
                          <div v-for="(item, index) in skuItem.value" :key="index">
                            <div class="item" v-show="item[
                              item.findIndex((i) => i.check == true) >= 0
                                ? item.findIndex((i) => i.check == true)
                                : 0
                            ].show
                              ">
                              <!-- 属性 -->
                              <div class="title" :class="{
                                center:
                                  !item[
                                    item.findIndex((i) => i.check == true) >=
                                      0
                                      ? item.findIndex((i) => i.check == true)
                                      : 0
                                  ].picUrl &&
                                  item[
                                    item.findIndex((i) => i.check == true) >=
                                      0
                                      ? item.findIndex((i) => i.check == true)
                                      : 0
                                  ].name.length < 30,
                              }">
                                <el-popover placement="right" trigger="hover">
                                  <img :src="item[
                                    item.findIndex(
                                      (i) => i.check == true
                                    ) >= 0
                                      ? item.findIndex(
                                        (i) => i.check == true
                                      )
                                      : 0
                                  ].picUrl
                                    " alt="" style="width: 300px; height: 300px" />
                                  <img :src="item[
                                    item.findIndex(
                                      (i) => i.check == true
                                    ) >= 0
                                      ? item.findIndex(
                                        (i) => i.check == true
                                      )
                                      : 0
                                  ].picUrl
                                    " alt="" v-if="item[
                                      item.findIndex(
                                        (i) => i.check == true
                                      ) >= 0
                                        ? item.findIndex(
                                          (i) => i.check == true
                                        )
                                        : 0
                                    ].picUrl
                                    " @click="
                                      getPic(
                                        item.find((i) => i.check == true).picUrl
                                      )
                                      " slot="reference" />
                                </el-popover>
                                <span :class="{
                                  maxLine:
                                    item[
                                      item.findIndex(
                                        (i) => i.check == true
                                      ) >= 0
                                        ? item.findIndex(
                                          (i) => i.check == true
                                        )
                                        : 0
                                    ].name.length > 30,
                                }" :title="item[
                                  item.findIndex((i) => i.check == true) >=
                                    0
                                    ? item.findIndex((i) => i.check == true)
                                    : 0
                                ].name
                                  ">
                                  {{
                                    item[
                                      item.findIndex((i) => i.check == true) >=
                                        0
                                        ? item.findIndex((i) => i.check == true)
                                        : 0
                                    ].name
                                  }}</span>
                              </div>
                              <!-- 价格 -->
                              <div class="price">
                                <span>$
                                  {{
                                    $fun.RMBNumSegmentation(
                                      $fun.ceil(
                                        item[
                                          item.findIndex(
                                            (i) => i.check == true
                                          ) >= 0
                                            ? item.findIndex(
                                              (i) => i.check == true
                                            )
                                            : 0
                                        ].price * $store.state.exchangeRate
                                      )
                                    )
                                  }}</span>

                                <span>
                                  ({{
                                    $fun.EURNumSegmentation(
                                      item[
                                        item.findIndex(
                                          (i) => i.check == true
                                        ) >= 0
                                          ? item.findIndex(
                                            (i) => i.check == true
                                          )
                                          : 0
                                      ].price
                                    )
                                  }}￥)</span>
                              </div>
                              <!-- 库存 -->
                              <div class="numGroup">
                                {{
                                  item[
                                    item.findIndex((i) => i.check == true) >= 0
                                      ? item.findIndex((i) => i.check == true)
                                      : 0
                                  ].amountOnSale
                                }}
                              </div>
                              <!-- 选择数量 -->
                              <div class="num">
                                <el-input-number v-model="item[
                                  item.findIndex((i) => i.check == true) >=
                                    0
                                    ? item.findIndex((i) => i.check == true)
                                    : 0
                                ].num
                                  " :step="1" :max="item[
                                    item.findIndex((i) => i.check == true) >=
                                      0
                                      ? item.findIndex((i) => i.check == true)
                                      : 0
                                  ].amountOnSale
                                    " :min="0" size="small" @change="
                                      maxNum(
                                        item.find((i) => i.check == true),
                                        item
                                      )
                                      ">
                                </el-input-number>
                              </div>
                            </div>
                          </div>
                          <div v-if="showNum == 0" class="Outofstock">
                            {{ $fanyi('商品暂无库存') }}
                          </div>
                        </div>

                        <!-- 下拉 -->
                        <div class="addShow" @click="addShow = !addShow" v-if="showNum > 4" :class="{ top: !addShow }">
                          <img class="jiahaoIcon" v-if="addShow" :src="require('@/assets/icon/putdown.png')" />
                          <img class="jiahaoIcon" v-else :src="require('@/assets/icon/putdown1.png')" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- 总计 -->

                  <div class="addClassGroup">
                    <!-- <button
                      @click="addCart(false)"
                      ref="start"
                      class="start"
                      id="start"
                      :disabled="forbid"
                    >
                      {{ $fanyi("加入购物车") }}
                    </button> -->
                    <!-- <i class="el-icon-shopping-cart-full"></i> -->
                    <!---->
                    <!-- <button @click="addTemporaryList">添加临时列表</button> -->
                  </div>
                </div>
                <div class="conRight-right">
                  <div class="totalGroup">
                    <div class="total">{{ $fanyi('产品数量') }}:</div>
                    <div class="total-num">{{ this.Count }}</div>
                    <div class="money">
                      <p class="title">{{ $fanyi('总计') }}:</p>
                      <p class="price">
                        $
                        {{
                          $fun.EURNumSegmentation(
                            isNaN(money)
                              ? ''
                              : $fun.ceil(money * $store.state.exchangeRate)
                          )
                        }}

                        ({{
                          $fun.RMBNumSegmentation(
                            !isNaN(money) ? $fun.ceil(money) : ''
                          )
                        }}
                        ￥)
                      </p>
                    </div>
                    <div class="addbtn">
                      <button @click="addCart(false)" ref="start" class="start" id="start" :disabled="isAddCart">
                        <img alt="" id="addCart" />
                        {{ $fanyi('加入购物车') }}
                      </button>
                    </div>
                  </div>
                  <div class="title-text">
                    <p>
                      {{
                        $fanyi(
                          '如果您想购买或者是需要拿样品，请与我们的工作人员联系'
                        )
                      }}
                    </p>
                    <div class="Talkbtn" @click="chat">
                      {{ $fanyi('联系工作人员') }}
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <!-- 临时清单 -->

          </div>
        </div>
        <!-- 商品详情 -->
        <div class="detail-box" v-loading="shopLoading" :element-loading-text="$fanyi('加载中')">
          <!-- 店铺 -->
          <div class="shop fl">
            <!-- 店铺情报 -->
            <div class="intelligence">
              <h4>{{ $fanyi('店铺信息') }}</h4>
              <p class="p" @click="goshopGoods">
                {{ details.shopName }}
              </p>
              <button @click="goshopGoods">
                {{ $fanyi('进入店铺') }}
              </button>
              <button v-if="!shopFavoriteStatus" class="toFavorite" @click="shopAddFavorite">
                {{ $fanyi('收藏店铺') }}
              </button>
              <button v-else class="toFavorite" @click="favoriteShopDelete">
                {{ $fanyi('移出收藏夹') }}
              </button>
            </div>
            <!-- 推荐 -->
            <div class="recommend">
              <h4>{{ $fanyi('商家推荐') }}</h4>
              <div class="recommendSlide" v-if="shopDetail">
                <el-carousel trigger="click" height="265px">
                  <el-carousel-item v-for="(item, index) in shopDetail.goods" :key="index" :autoplay="false">
                    <div class="recommendSlideCon">
                      <div class="recommendSlideConOpt" v-for="(otem, ondex) in item" :key="ondex" @click="
                        $fun.toCommodityDetails({
                          goods_id: otem.goods_id,
                          shop_type: otem.shop_type,
                        })
                      $router.go(0)
                        ">
                        <img :src="otem.imgUrl" alt="" />
                        <div class="goodsTitle">
                          <p>{{ otem.title }}</p>
                          <p>
                            <span>
                              ${{
                                $fun.EURNumSegmentation(
                                  $fun.ceil(
                                    otem.goodsPrice * $store.state.exchangeRate
                                  )
                                )
                              }}</span>
                            （{{ $fun.RMBNumSegmentation(otem.goodsPrice) }}￥）
                          </p>
                        </div>
                      </div>
                    </div>
                  </el-carousel-item>
                </el-carousel>
              </div>
            </div>
          </div>
        </div>
        <!-- 详细介绍 -->
        <div class="goodsDetail">
          <h4>{{ $fanyi('商品属性') }}</h4>
          <div class="property">
            <p v-for="(item, index) in details.goodsInfo.detail" :key="index">
              <span :title="index"> {{ index }}:</span><span :title="item">{{ item }}</span>
            </p>
          </div>
          <h4 style="margin-top: 60px">{{ $fanyi('商品详情') }}</h4>
          <div id="detail" ref="detail">
            <div class="commoditydetails" v-html="details.description"></div>
          </div>
        </div>
      </div>

      <div v-else class="notHaveGoods" v-show="!loading">
        <div class="hintInfo">
          <img src="../../assets/newImg/default.png" alt="" />
          <p>
            {{
              $fanyi('该商品因库存不足或其他原因已经找不到，请查看其他商品！')
            }}
          </p>
          <p>
            <button class="backHomePage" @click="$fun.toPage('/')">
              {{ $fanyi('返回首页') }}
            </button>
          </p>
        </div>
      </div>
      <Foot></Foot>
    </div>
  </div>
</template>
<script>
import Dialog from '../../components/public/Dialog'
import Foot from '../../components/foot/Foot'
import PicZoom from 'vue-piczoom'
import HomePageTop from '../../components/head/HomePageTop.vue'

export default {
  name: 'productDetails',

  data() {
    return {
      addShow: true,
      showNum: 0, // 显示选择条数
      isAddCart: false,
      token: localStorage.getItem('user_token'),
      datas: {
        goods_id: this.$route.query.goods_id,
        shop_type: this.$route.query.shop_type,
      },
      loading: true,
      shopLoading: true,
      details: '', //详情
      shopDetail: null, //商店推荐商品
      goodsInfo: {}, //商品属性
      detailsImg: '', // 主图
      detailsImgIndex: 0, // 图片索引
      isColorShow: false, // 是否显示颜色图 true显示图，false显示尺寸css
      isSizeShow: false, //
      showType: 'img',
      num: 1, // 数量
      price: 0, //价格
      max: 1, //
      skuProps: {}, //所有规格数据
      skuPropsChinese: {}, //所有规格数据中文
      money: 0,
      flag: false,

      counts: 0,
      Count: 0, // 总数
      skuId: '',
      specId: '',
      // 选中的商品类别
      goodsStr: '',
      error: false,
      // 临时清单数组
      TemporaryListing: [],
      goodsInventoryStr: {},
      config: {
        top: '20vh',
        width: '525px',
        title: this.$fanyi('提示'),
        btnTxt: [this.$fanyi('确定')],
      },
      goodsFavoriteStatus: false, //商品是否已收藏
      shopFavoriteStatus: false, //店铺是否已收藏
      goods_inventory: '', //商品的所有属性选项，添加购物车用
      forbid: false, //禁止点击
    }
  },
  components: {
    Foot,
    PicZoom,
    HomePageTop,
    Dialog,
  },
  created() {
    this.productDetails()
  },
  mounted() { },
  methods: {
    goLeft($event) {


      var node = $event.currentTarget.parentNode

      let timer = setInterval(function () {
        let osTop = node.scrollLeft

        let ispeed = Math.floor(-osTop / 15)
        node.scrollLeft = node.scrollLeft =
          osTop + ispeed

        if (osTop === 0) {
          clearInterval(timer)
        }
      }, 10)
    },
    goRight($event, type) {
      var node = $event.currentTarget.parentNode
      if (type) {
        this.timerCon = setInterval(function () {
          node.scrollLeft += 10
        }, 10)
      } else {
        clearInterval(this.timerCon)
      }
    },
    // 左箭头点击滑动
    leftarrow() {
      let imgbox = document.querySelector('.images')
      imgbox.style.cssText =
        'transform: translateX(10px)!important;  transition: all 1s;'
    },
    // 右箭头滑动
    rightarrow() {
      let imgbox = document.querySelector('.images')
      if (this.details.video) {
        imgbox.style.cssText =
          'transform: translateX(-200px)!important;  transition: all 1s;'
      } else {
        imgbox.style.cssText =
          'transform: translateX(-90px)!important;  transition: all 1s;'
      }
    },
    // 查看商品是否已收藏
    favoriteShopCheckIn() {
      this.$api
        .favoriteShopCheckIn({
          shop_type: this.$route.query.shop_type,
          shop_name: JSON.stringify([this.details.shopName]),
        })
        .then((res) => {
          if (res.code != 0) return false
          this.shopFavoriteStatus = res.data[0].inFavorite
        })
    },
    // 店铺收藏移除
    favoriteShopDelete() {
      this.$api
        .favoriteShopDelete({
          shop_type: this.$route.query.shop_type,
          shop_name: this.details.shopName,
        })
        .then((res) => {
          if (res.code != 0) return false
          this.shopFavoriteStatus = !this.shopFavoriteStatus
          this.$message(this.$fanyi('移除收藏夹成功'))
          this.$forceUpdate()
        })
    },
    chat(e) {
      let element = document.getElementById('zsiq_float')
      // 创建事件
      var event = document.createEvent('MouseEvents')
      // 定义事件 参数： type, bubbles, cancelable
      event.initEvent('click', true, true)
      // 触发对象可以是任何元素或其他事件目标
      element.dispatchEvent(event)
      // if (this.$store.state.userInfo) {

      // } else {
      //   this.$refs.dialog.open(
      //     this.$fanyi('是否前往登录?'),
      //     () => {
      //       this.$store.commit('getactivePage', this.$route.fullPath)
      //       this.$fun.toPage('/login')
      //     },
      //     () => { }
      //   )
      // }
    },
    // 将店铺加入收藏夹
    shopAddFavorite() {
      if (!this.$store.state.userInfo) {
        return this.$refs.dialog.open(
          this.$fanyi('请先登录账号'),
          () => {
            this.$fun.toPage('/login')
          },
          () => { }
        )
      }
      this.$api
        .favoriteShopAdd({
          shop_type: this.$route.query.shop_type,
          shop_name: this.details.shopName,
          shop_id: this.details.shopId
        })
        .then((res) => {
          if (res.code != 0) return false

          this.shopFavoriteStatus = !this.shopFavoriteStatus
          this.$message({
            message: this.$fanyi('收藏店铺成功'),
            type: 'success',
          })
          this.$forceUpdate()
        })
    },
    // 查看商品是否已收藏
    favoriteGoodsCheckIn() {
      this.$api
        .favoriteGoodsCheckIn({
          goods_id: JSON.stringify([this.$route.query.goods_id]),
        })
        .then((res) => {
          if (res.code != 0) return false
          this.goodsFavoriteStatus = res.data[0].inFavorite
        })
    },
    // 添加收藏商品
    favoriteGoodsAdd() {
      if (!this.$store.state.userInfo) {
        return this.$refs.dialog.open(
          this.$fanyi('请先登录账号'),
          () => {
            this.$fun.toPage('/login')
          },
          () => { }
        )
      }

      let datas = {
        goods_id: this.details.goods_id,
        title: this.details.title,
        image_url: this.details.images[0],
        price: this.price,
        shop_type: this.$route.query.shop_type,
      }
      if (!this.goodsFavoriteStatus) {
        this.$api.favoriteGoodsAdd(datas).then((res) => {
          if (res.code != 0) return
          this.goodsFavoriteStatus = !this.goodsFavoriteStatus
          if (this.goodsFavoriteStatus) {
            this.$message({
              message: this.$fanyi('添加收藏成功'),
              type: 'success',
            })
          }
          this.$forceUpdate()
        })
      } else {
        this.$api
          .favoriteGoodsDelete({
            goods_id: this.details.goods_id,
            // id: "[]",
          })
          .then((res) => {
            if (res.code != 0) return
            this.goodsFavoriteStatus = !this.goodsFavoriteStatus
            if (this.goodsFavoriteStatus == false) {
              this.$message(this.$fanyi('取消收藏成功'))
            }
            this.$forceUpdate()
          })
      }
    },
    // 获取商品详情
    productDetails() {
      this.$api.goodsParticularsV2(this.datas).then((res) => {
        this.loading = false
        if (res.code != 0 || !res.data.goodsInfo) return
        this.details = res.data

        if (res.data.video !== '' && res.data.video !== null) {
          this.showType = 'video'
        }

        // 对象
        const priceRanges = res.data.goodsInfo.priceRanges

        document.title = 'Rakumart-' + res.data.title.substr(0, 50)

        // 原生获取商品详情，图片翻译列表
        this.$nextTick(() => {
          // 图片标签
          setTimeout(() => {
            // 让所有的table 表格居中显示
            let tables = document
              .getElementById('detail')
              .querySelectorAll('table')
            tables?.forEach((i, ind) => {
              i.style.margin = '0 auto'
            })
            let images = document
              .getElementById('detail')
              .querySelectorAll('img')
            images?.forEach((item, ind) => {
              //
              if (item.width > 60 && item.width > 45) {
                item.addEventListener('mousemove', (e) => {
                  if (!e.target.getAttribute('flag')) {
                    e.target.setAttribute('flag', true)
                    let div = document.createElement('div')
                    let button = document.createElement('button')
                    let loadCss = document.createElement('style')
                    let loadbox = document.createElement('p')
                    let pic = document.createElement('span')
                    div.style.position = 'relative'
                    div.style.display = 'inline-block'
                    div.style.margin = '0 auto'
                    loadbox.style.cssText =
                      'display:none; width:0px; height: 0px;background:#313131; opacity: 0.5;position: absolute;top: 0;left: 0;z-index:0; '
                    pic.style.cssText =
                      'display: block; 50px;width:50px;height: 50px;border-radius: 50%;position: absolute;top: 50%;left: 50%;z-index:9990; box-shadow: 3px 3px 0 0px #ff730b; transform: translate(-50%, -50%);animation: loop 1s infinite;-webkit-animation: loop 1s infinite;'

                    div.style.width = e.target.width + 'px'
                    div.style.height = e.target.height + 'px'
                    loadbox.style.width = e.target.width + 'px'
                    loadbox.style.height = e.target.height + 'px'
                    loadCss.type = 'text/css'
                    loadCss.innerHTML =
                      '@keyframes loop { 0%{transform: rotate(0deg);} 100%{ transform: rotate(360deg);}'
                    document.head.appendChild(loadCss)
                    button.className = 'translation'
                    button.style.width = '70px'
                    button.style.zIndex = '10'
                    button.style.height = '70px'
                    if (e.target.width > 1300) {
                      button.style.top = '0'
                      button.style.left = '0'
                    } else {
                      button.style.top = '0'
                      button.style.right = '0'
                    }
                    button.style.backgroundImage =
                      'url(' + require('../../assets/newImg/order/translation-1.svg') + ') '
                    button.style.position = 'absolute'
                    button.style.backgroundSize = 'cover'
                    e.target.parentNode.replaceChild(div, e.target)
                    e.target.appendChild(button)
                    loadbox.appendChild(pic)
                    div.appendChild(loadbox)
                    button.addEventListener('click', (event) => {
                      event.stopPropagation()
                      event.preventDefault()
                      loadbox.style.display = 'block'
                      button.style.zIndex = '99'
                      let imgurlarr = []
                      imgurlarr.push(event.target.previousElementSibling.src)
                      this.$api
                        .pictureTranslation({
                          picUrl: imgurlarr,
                          language: 'en',
                        })
                        .then((res) => {
                          if (res.code !== 0) return

                          if (!res.data[0].url) return
                          event.target.previousElementSibling.src =
                            res.data[0].url
                          event.target.previousElementSibling.onload = (e) => {
                            loadbox.style.display = 'none'
                          }

                          event.target.remove()

                          this.$forceUpdate()
                        })
                    })
                    // let Translation = document.querySelectorAll('.translation')

                    div.appendChild(e.target)
                    div.appendChild(button)
                  }

                  // e.target.addChild(div)
                })
              }
            })
          }, 1000)
        })

        // 数组

        if (Array.isArray(priceRanges)) {
          this.price =
            res.data.goodsInfo.priceRanges[
              res.data.goodsInfo.priceRanges.length - 1
            ].price
        } else {
          this.price = res.data.goodsInfo.priceRanges.priceMin
        }

        this.goodsInfo = res.data.goodsInfo
        this.getShopRecommendedGoods()
        this.dataProcessing()
        this.favoriteGoodsCheckIn()
        this.favoriteShopCheckIn()
      })
    },
    // 获取商品推荐商品
    async getShopRecommendedGoods() {
      await this.$api
        .getShopRecommendedGoods({
          shopName: this.details.shopName,
          // shopType: 1688,
          shopId: this.details.shopId
        })
        .then((res) => {
          this.shopLoading = false
          if (res.code != 0) return
          let lack = 5 - (res.data.goods.length % 5) //取得店铺商品数离5个的倍数差多少个商品的数量
          //差多少个就从最前面取多少个填充到最后
          for (let i = 0; i < lack; i++) {
            res.data.goods.push(res.data.goods[i])
          }
          // 将商品详情的商品分成5个一组
          res.data.goods = this.$fun.changeArr(res.data.goods, 5)

          this.shopDetail = res.data
        })
    },

    // 处理数据
    dataProcessing() {
      // 英文 sku
      let skuProps = []

      Object.keys(this.details.goodsInfo.specification)?.forEach((key) => {

        skuProps.push({
          key: key.substr(1, key.length - 2),
          value: this.details.goodsInfo.specification[key],
          active: 0,
        })
      })

      // 找到最后一个  复制出来，然后删掉
      let arr = skuProps.slice(skuProps.length - 1, skuProps.length)
      skuProps.splice(skuProps.length - 1, 1)

      // 给每项加需要的字段

      arr[0].value?.forEach((item) => {
        this.details.goodsInfo.goodsInventory?.forEach((i, index) => {
          if (i.keyC.indexOf(item.name) != -1) {
            this.details.goodsInfo.goodsInventory[index].valueC?.forEach(
              (ind) => {
                ind.picUrl = item.picUrl

                if (ind.name != item.name) {
                  ind.name = item.name
                }
                ind.key = i.keyC.substring(0, i.keyC.length)
                ind.num = 0
              }
            )
          }
        })
      })
      //   添加最后一项
      skuProps.push({
        key: arr[0].key,
        value: [],
        active: -1,
      })
      // 添加最后一项的value  size
      this.details.goodsInfo.goodsInventory?.forEach((ite) => {
        skuProps[skuProps.length - 1].value.push(ite.valueC)
      })

      // 中文sku
      let skuPropsChinese = []

      Object.keys(this.details.goodsInfo.specificationChinese)?.forEach(
        (key) => {
          skuPropsChinese.push({
            key: key.substr(1, key.length - 2),
            value: this.details.goodsInfo.specificationChinese[key],
            active: 0,
          })

        }
      )

      // 找到最后一个  复制出来，然后删掉
      let arrChinese = skuPropsChinese.slice(
        skuPropsChinese.length - 1,
        skuPropsChinese.length
      )
      skuPropsChinese.splice(skuPropsChinese.length - 1, 1)

      // 给每项加需要的字段

      arrChinese[0].value?.forEach((item) => {
        this.details.goodsInfo.goodsInventoryChinese?.forEach((i, index) => {
          if (i.keyC.indexOf(item.name) != -1) {
            this.details.goodsInfo.goodsInventoryChinese[index].valueC?.forEach(
              (ind) => {
                ind.key = i.keyC.substring(0, i.keyC.length)
                if (ind.key == item.name) {
                  ind.name = item.name
                }
              }
            )
          }
        })
      })

      //   添加最后一项
      skuPropsChinese.push({
        key: arrChinese[0].key,
        value: [],
        active: -1,
      })

      // 添加最后一项的value  size
      this.details.goodsInfo.goodsInventoryChinese?.forEach((ite) => {
        skuPropsChinese[skuPropsChinese.length - 1].value.push(ite.valueC)
      })

      skuPropsChinese?.forEach((item, index) => {
        skuProps[index].keyChinese = item.key
        item.value?.forEach((items, indexs) => {
          if (items.constructor == Object) {
            if (skuProps[index].value[indexs]) {
              skuProps[index].value[indexs].nameChinese = items.name
              skuProps[index].value[indexs].keyChinese = items.key
            }
          } else if (items.constructor == Array) {
            items?.forEach((acc, ind) => {
              if (skuProps[index].value[indexs].constructor == Array) {
                skuProps[index].value[indexs][ind].nameChinese = acc.name
                skuProps[index].value[indexs][ind].keyChinese = acc.key
              }
            })
          }
        })
      })



      this.skuProps = skuProps

      this.skuPropsChinese = skuPropsChinese
      this.detailsImgIndex = 0
      this.detailsImg = this.details.images[0]
      //     属性大于一项     不要添加最后一项属性
      if (skuProps.length > 1) {
        for (let i in skuProps) {
          if (i < skuProps.length - 1) {

            this.getColor(skuProps[i], 0)
          }
        }
      } else {
        this.getColor(skuProps[0], 0)
      }
    },
    // 切换主图
    getPIc(pic, index) {
      this.detailsImgIndex = index
      this.detailsImg = pic
    },
    goshopGoods() {
      if (this.$fun.isOfficialWeb()) {
        __bl.sum('通过商品详情进入店铺数量统计');
      }
      this.$fun.toPage('/shopGoods?shopName=' + this.details.shopName + '&shopId=' + this.details.shopId)
    },
    // 颜色选择
    getColor(skuItem, index) {
      this.num = 1
      skuItem.active = index
      this.$forceUpdate()
      let str = ''
      for (let i in this.skuProps) {
        if (this.skuProps[i].active != -1) {
          str += this.skuProps[i].value[this.skuProps[i].active].name + '㊖㊎'
        }
      }

      str = str.substr(0, str.length - 2)
      console.log(str, '2222');
      this.goodsStr = str

      // 如果是有图片的选项就替换主图为选项图片

      if (skuItem.value[index].picUrl) {
        this.detailsImg = skuItem.value[index].picUrl

        this.detailsImgIndex = -1
        this.getPrice(str)

        this.$forceUpdate()
      } else {
        this.getPrice(str)
      }
    },
    // 查看大图
    getPic(pic) {
      this.detailsImg = pic
      this.$forceUpdate()
    },
    // 判断购买数量是否大于在库数量
    maxNum(i, item) {
      let throttling = true
      this.Count = 0
      this.money = 0

      item?.forEach((ind) => {
        if (
          i.num >= ind.startQuantity && ind.startQuantity_last !== null
            ? i.num < ind.startQuantity_last
            : true
        ) {
          if (throttling) {
            ind.check = true
            ind.num = i.num
            throttling = false
          } else {
            ind.check = false
          }
        } else {
          ind.check = false
        }
      })
      this.skuProps[this.skuProps.length - 1].value.forEach((items) => {
        items?.forEach((acc) => {
          if (acc.check) {
            this.Count += acc.num
            this.money += acc.num * acc.price
          }
        })
      })
      this.$forceUpdate()
    },
    // 根据选择条件匹配价格
    getPrice(str) {

      this.showNum = 0
      this.skuProps[this.skuProps.length - 1].value?.forEach((item) => {
        // 只有一个属性全部显示

        if (this.skuProps.length == 1) {
          if (item.constructor == Array) {
            item?.forEach((i) => {
              i.show = true
              if (!i.picUrl) {
                i.priImg = this.detailsImg
              }
              this.showNum++
            })
          }
          if (item.constructor == Object) {
            item.show = true
            if (!item.picUrl) {
              item.priImg = this.detailsImg
            }
            this.showNum++
          }
        } else {
          // 除了最后一个属性，其他都要相同
          item?.forEach((ind) => {
            ind.str = ind.key.substr(0, str.length)

            if (ind.str == str) {
              ind.show = true
              if (!ind.picUrl) {
                ind.priImg = this.detailsImg
              }
              this.showNum++
            } else {
              ind.show = false
            }
          })
        }
      })
    },
    // 删除临时菜单数据 暂时用不到
    TemporaryListingDel(i) {
      this.TemporaryListing.splice(i, 1)
    },
    // 添加选择到临时菜单 暂时用不到
    addTemporaryList() {
      let detail = []
      for (let i in this.skuProps) {
        detail.push({
          key: this.skuProps[i].key,
          value: this.skuProps[i].value[this.skuProps[i].active].name,
        })
      }
      let goods = {
        sku_id: this.skuId,
        spec_id: this.specId,
        price: this.price,
        num: this.num,
        pic: this.detailsImg,
        detail: detail,
        Inventario: this.Count,
      }
      let add = 1
      this.TemporaryListing?.forEach((item) => {
        if (JSON.stringify(item.detail) == JSON.stringify(goods.detail)) {
          item.num += goods.num
          add = 2
        }
      })
      if (add == 1) {
        this.TemporaryListing.push({
          sku_id: this.skuId,
          spec_id: this.specId,
          price: this.price,
          num: this.num,
          pic: this.detailsImg,
          detail: detail,
          Inventario: this.Count,
        })
      }

      this.$message.success(this.$fanyi('添加成功'))
    },
    addCart(type) {
      if (!this.$store.state.userInfo) {
        return this.$refs.dialog.open(
          this.$fanyi('请先登录账号'),
          () => {
            this.$fun.toPage('/login')
          },
          () => { }
        )
      }

      // 未登录时返回登陆页
      if (this.token == null) {
        let url = this.$route.fullPath
        this.$store.commit('getactivePage', url)
        this.$fun.toPage('/login')

        return false
      }

      var detail = []

      let sku = this.skuProps[this.skuProps.length - 1].value

      sku?.forEach((item) => {
        // 将选中规格的中文数据传入后台
        item?.forEach((i) => {
          if (i.check == true && i.num > 0) {
            let detailChinese = []
            this.skuProps?.forEach((items, indexs) => {
              detailChinese.push({
                key: items.keyChinese,
                value: i.keyChinese.split('㊖㊎')[indexs],
              })
            })

            detail.push({
              sku_id: i.skuId,
              spec_id: i.specId,
              price: i.price,
              num: i.num,
              pic: i.picUrl ? i.picUrl : i.priImg, //item.pic ? item.pic :
              detailChinese: detailChinese,
            })
          }
        })
      })

      //   去sku单价
      let priceRanges = []

      if (this.goodsInfo.priceRanges.length < 2)
        this.goodsInfo.priceRanges?.forEach((item) => {
          priceRanges.push({
            startQuantity: item.startQuantity,
            price: this.price,
          })
        })

      JSON.stringify(this.goodsInfo.priceRanges)
      let trace = ''
      // 进行判断
      if (this.$route.query.type == 'linkSearch') {
        trace = 'linkSearch'
      }
      if (this.$route.query.type == 'keywordSearch') {
        trace = 'keywordSearch'

      }
      if (this.$route.query.type == 'imgSearch') {
        trace = 'imgSearch'
      }
      if (this.$route.query.type == 'classificationSearch') {
        trace = 'classificationSearch'
      }
      if (this.$route.query.type == 'taobaolinkSearch') {
        trace = 'taobaolinkSearch'
      } if (this.$route.query.type == '1688linkSearch') {
        trace = '1688linkSearch'
      }
      if (this.$route.query.type == 'tmalllinkSearch') {
        trace = 'tmalllinkSearch'
      } if (this.$route.query.type == 'rakumartlinkSearch') {
        trace = 'rakumartlinkSearch'
      }

      let datas = {
        shop_id: this.details.shopId,
        shop_name: this.details.shopName,
        goods_id: this.details.goods_id,
        goods_title: this.details.title,
        goods_specification: detail != false ? JSON.stringify(detail) : null,
        from_platform: 1688,
        trace: trace,
        priceRanges:
          priceRanges != false
            ? JSON.stringify(priceRanges)
            : JSON.stringify(this.goodsInfo.priceRanges),
      }

      if (datas.goods_specification == null)
        return this.$alert(this.$fanyi('请选择商品'))
      //   // type是true时代表多个一起添加

      this.isAddCart = true

      this.$api.goodsToCart(datas).then((res) => {
        if (res.code != 0) return this.$alert(this.$fanyi('购物车添加失败'))

        if (this.$route.query.type == 'linkSearch') {

          if (this.$fun.isOfficialWeb()) {
            __bl.sum('通过链接搜索进入商品详情且加入购物车数量统计');
          }
        }
        if (this.$route.query.type == 'keywordSearch') {

          if (this.$fun.isOfficialWeb()) {
            __bl.sum('通过关键词搜索进入商品详情且加入购物车数量统计');
          }
        }
        if (this.$route.query.type == 'imgSearch') {
          if (this.$fun.isOfficialWeb()) {
            __bl.sum('通过图片搜索进入商品详情且加入购物车数量统计');
          }

        }
        if (this.$route.query.type == 'classificationSearch') {
          if (this.$fun.isOfficialWeb()) {
            __bl.sum('通过分类搜索进入商品详情且加入购物车数量统计');
          }
        }

        if (this.$route.query.type == 'taobaolinkSearch') {
          if (this.$fun.isOfficialWeb()) {
            __bl.sum('通过淘宝链接搜索进入商品详情且加入购物车数量统计');
          }
        } if (this.$route.query.type == '1688linkSearch') {
          if (this.$fun.isOfficialWeb()) {
            __bl.sum('通过1688链接搜索进入商品详情且加入购物车数量统计');
          }
        }
        if (this.$route.query.type == 'tmalllinkSearch') {
          if (this.$fun.isOfficialWeb()) {
            __bl.sum('通过天猫链接搜索进入商品详情且加入购物车数量统计');
          }
        } if (this.$route.query.type == 'rakumartlinkSearch') {
          if (this.$fun.isOfficialWeb()) {
            __bl.sum('通过rakumart链接搜索进入商品详情且加入购物车数量统计');
          }
        }
        let img = document.querySelector('#addCart')
        img.src = require('../../assets/cart/cart.gif')
        img.style.display = 'block'
        setTimeout(() => {
          img.src = ''
          img.style.display = 'none'
          this.isAddCart = false
        }, 3000)
        this.$message.success(this.$fanyi('购物车添加成功'))
        this.$store.dispatch('goodsToCartNum')
        this.$forceUpdate()
        this.qingKong()


      })
    },
    // 清空加购物车的数据
    qingKong() {
      this.Count = 0
      this.money = 0
      this.skuProps[this.skuProps.length - 1].value?.forEach((item) => {
        item.num = 0
      })
      this.$forceUpdate()
    },
  },
  watch: {},
}
</script>

<style lang="scss" scoped>
@import '../../css/mixin';

.Outofstock {
  width: 100%;
  height: 60px;
  text-align: center;
  line-height: 60px;
}

#ProductDetails {
  .main {
    background-color: #f6f6f6;
    color: #222;
    padding-bottom: 20px;

    .pri {
      text-align: center;
      margin-left: -20px;
    }

    // overflow: hidden;
    .renminbi {
      font-size: 20px;
    }

    .contentBackground {
      width: 100%;
      background-color: #f6f6f6;
      padding-bottom: 0px;

      .content {
        .detailTop {
          margin-bottom: 70px;

          .detaiCon {
            display: flex;

            .conLeft {
              width: 480px;
              margin-right: 20px;
            }

            .conRight {
              .conRight-head {
                display: block;
                width: 630px;
                padding: 20px;
                background-color: #fff;
                color: #222222;
                border-radius: 6px;
                border: 1px solid #e2e2e2;
                margin-bottom: 20px;

                h4 {
                  font-weight: 400;
                  font-size: 18px;
                  color: #222222;
                  line-height: 26px;
                  margin-bottom: 20px;
                }

                .price-range {
                  font-size: 16px;

                  .row {
                    display: flex;
                    margin-bottom: 16px;
                    align-items: center;

                    .label {
                      width: 80px;
                    }

                    .value {
                      flex: 1;
                      display: flex;

                      .priceConOpt {
                        min-width: 168px;
                        color: #ff730b;

                        margin-right: 10px;

                        .price {
                          font-weight: bold;
                          font-size: 24px;
                          font-size: 24px;
                          font-family: Roboto-Bold, Roboto;
                          font-weight: bold;
                          color: #ff730b;
                        }

                        // &:last-child {
                        //   font-size: 20px;
                        // }

                        .nums {
                          font-size: 14px;
                          font-family: PingFangSC-Regular, PingFang SC;
                          font-weight: 400;
                          color: #666666;
                        }
                      }

                      .priceConOptobj {
                        min-width: 128px;
                        color: #ff730b;

                        margin-right: 10px;

                        .price {
                          font-weight: bold;
                          font-size: 24px;
                          font-size: 24px;
                          font-family: Roboto-Bold, Roboto;
                          font-weight: bold;
                          color: #ff730b;
                        }

                        // &:last-child {
                        //   font-size: 20px;
                        // }

                        .nums {
                          font-size: 14px;
                          font-family: PingFangSC-Regular, PingFang SC;
                          font-weight: 400;
                          color: #666666;
                        }
                      }

                      .bolang {
                        min-width: 20px;
                        color: #ff730b;
                        display: flex;
                        align-items: center;
                        font-weight: 700;
                      }
                    }
                  }
                }

                h5 {
                  padding: 10px;
                  line-height: 20px;
                  background: #fff2e8;
                  font-size: 12px;
                  font-weight: normal;
                  border-radius: 6px;
                  width: 590px;

                  background: #fff2e8;
                  border-radius: 6px;

                  i {
                    font-size: 20px;
                    color: #ff730b;
                    margin-right: 10px;
                  }
                }
              }

              .conRightTitle {
                font-size: 16px;
                font-weight: 400;
                color: #222;
                margin-bottom: 20px;
              }

              .kind {
                width: 630px;
                background: #ffffff;
                border: 1px solid #e9e9e9;
                border-radius: 5px;
                padding: 20px;
                padding-bottom: 10px;

                .kindCon {
                  display: flex;
                  flex-direction: column;
                  align-items: top;
                  position: relative;

                  .conRightTitle {
                    width: auto;
                    font-size: 16px;
                    font-weight: 600;
                    display: block;
                  }

                  .ConBody {
                    display: flex;
                    width: 100%;
                    flex-wrap: wrap;
                    // margin-top: 20px;
                    margin-bottom: 15px;
                    // justify-content: space-between;

                    .choiceColor {
                      color: #ffa724;
                      border-color: #ff730b !important;
                      border-radius: 4px;
                    }

                    .sizeItem {
                      display: flex;
                      align-items: center;

                      margin-right: 11px;
                      background: #ffffff;
                      border: 1px solid #cccccc;
                      padding: 9px 15px;
                      cursor: pointer;
                      border-radius: 4px;
                      white-space: nowrap;
                      margin-bottom: 15px;

                      img {
                        width: 40px;
                        height: 40px;
                        margin: -4px 10px -4px -9px;
                      }

                      span {
                        max-width: 200px;
                        display: block;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                      }
                    }

                    .controlButton {
                      position: sticky;
                      height: 62px;
                      width: 12px;
                      background-color: #e4e4e4;
                      line-height: 62px;

                      &:first-child {
                        left: 0;
                      }

                      &:last-child {
                        position: absolute;
                        right: 0px;
                      }
                    }
                  }

                  .allCheck {
                    // margin-top: 20px;
                    font-size: 12px;

                    .allHead {
                      display: flex;
                      height: 40px;
                      width: 590px;
                      height: 40px;
                      background: #f6f6f6;
                      border-radius: 6px;
                      font-size: 14px;
                      font-family: Roboto-Medium, Roboto;
                      font-weight: 500;
                      color: #222222;
                    }

                    .list {
                      overflow: hidden;
                      // transition: all 0.3s;
                    }

                    .item {
                      display: flex;
                      padding: 10px 0;
                      border-bottom: 1px solid #eeeeee;
                      height: 60px;

                      /deep/ .el-input-number__decrease {
                        width: 24px;
                        height: 24px;
                        background: #f2f2f2;
                        line-height: 24px;
                        text-align: center;
                        margin-left: 4px;
                        margin-top: 3px;
                        border-radius: 2px;
                        background: #f2f2f2;
                      }

                      /deep/.el-input-number__increase {
                        width: 24px;
                        height: 24px;
                        background: #f2f2f2;
                        line-height: 24px;
                        text-align: center;
                        margin-right: 4px;
                        margin-top: 3px;
                        border-radius: 2px;
                        background: #f2f2f2;
                      }
                    }

                    img {
                      width: 40px;
                      //   height: 40px;
                      margin-right: 10px;
                      cursor: pointer;
                    }

                    .title {
                      flex: 1.3;
                      display: flex;
                      justify-content: left;
                      align-items: center;
                      margin-right: 10px;
                    }

                    .price {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      flex: 1;

                      span {
                        &:first-child {
                          font-size: 14px;
                          font-family: Roboto-Bold, Roboto;
                          font-weight: bold;
                          color: #ff730b;
                        }

                        &:last-child {
                          font-size: 14px;
                          font-family: Roboto-Regular, Roboto;
                          font-weight: 400;
                          color: #ff730b;
                          margin-left: 3px;
                        }
                      }
                    }

                    .center {
                      justify-content: center;
                      text-align: center;
                    }

                    .maxLine {
                      text-overflow: -o-ellipsis-lastline;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      display: -webkit-box;
                      -webkit-line-clamp: 2;
                      -webkit-box-orient: vertical;
                    }

                    .numGroup {
                      flex: 1;
                      display: flex;
                      justify-content: center;
                      text-align: center;
                      align-items: center;
                    }

                    .num {
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      padding: 0 5px;
                      flex: 1;
                    }

                    .addShow {
                      cursor: pointer;
                      font-size: 12px;
                      color: #1c2899;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      margin-bottom: 10px;

                      img {
                        margin-left: 6.72px;
                        width: 20px;
                      }
                    }

                    .top {
                      margin-top: 15px;
                    }
                  }
                }
              }

              /deep/.el-input-number {
                border: none;
              }

              @keyframes test {

                /* from表示动画的开始位置，也可以使用0%来表示。 */
                from {
                  transform: translate(-50px, -0px);
                  font-size: 50px;
                }

                /* to表示动画的结束位置，也可以使用100%来表示。 */
                to {
                  transform: translate(520px, -650px);
                  font-size: 16px;
                }
              }

              .addClassGroup {
                padding-left: 20px;
                padding-bottom: 20px;
                position: relative;

                .el-icon-shopping-cart-full {
                  transition: 2s;
                  position: absolute;
                  top: 0;
                  z-index: -1;
                  left: 100px;

                  color: #ffa724;
                  font-size: 50px;
                }

                >button {
                  width: 200px;
                  height: 50px;
                  background: #ffa724;
                  border-radius: 0px;
                  font-size: 18px;
                  font-weight: 400;
                  color: #ffffff;

                  &:hover {
                    opacity: 0.7;
                  }
                }
              }
            }

            .conRight-right {
              margin-left: 20px;

              .totalGroup {
                margin-bottom: 30px;

                width: 250px;
                min-height: 254px;
                background: #ffffff;
                border-radius: 6px;
                border: 1px solid #e2e2e2;
                padding: 30px 20px 20px 20px;

                .total {
                  font-size: 14px;
                  font-family: Roboto-Regular, Roboto;
                  font-weight: 400;
                  color: #666666;
                  width: 100%;
                  text-align: right;
                }

                .total-num {
                  font-size: 18px;
                  font-family: Roboto-Medium, Roboto;
                  font-weight: 600;
                  height: 30px;
                  margin-top: 5px;
                  color: #222222;
                  text-align: right;
                  border-bottom: 1px solid #e2e2e2;
                }

                .money {
                  width: 100%;
                  min-height: 71px;
                  padding: 10px 0;
                  border-bottom: 1px solid #e2e2e2;
                  margin-bottom: 20px;

                  .title {
                    text-align: right;
                    width: 100%;
                    font-size: 14px;
                    font-family: Roboto-Regular, Roboto;
                    font-weight: 400;
                    color: #666666;
                  }

                  .price {
                    margin-top: 5px;
                    text-align: right;

                    font-size: 18px;
                    // min-height: 24px;
                    font-family: Roboto-Black, Roboto;
                    font-weight: 900;
                    color: #ff730b;
                  }
                }

                .addbtn {
                  width: 210px;
                  height: 50px;
                  background: #ff730b;
                  border-radius: 4px;
                  font-size: 16px;
                  font-family: Roboto-Medium, Roboto;
                  font-weight: 500;
                  color: #ffffff;

                  .start {
                    width: 210px;
                    height: 50px;
                    background: #ff730b;
                    border-radius: 4px;
                    font-size: 16px;
                    font-family: Roboto-Medium, Roboto;
                    font-weight: 500;
                    color: #ffffff;
                  }
                }
              }

              .title-text {
                height: 106px;
                width: 250px;
                border-bottom: 1px solid #e2e2e2;

                p {
                  width: 100%;
                  font-size: 12px;
                  font-family: Roboto-Regular, Roboto;
                  font-weight: 400;
                  color: #666666;
                  line-height: 18px;
                  margin-bottom: 10px;
                }

                .Talkbtn {
                  width: 250px;
                  height: 40px;
                  background: #608bff;
                  border-radius: 20px;
                  line-height: 40px;
                  text-align: center;
                  font-size: 14px;
                  font-family: Roboto-Medium, Roboto;
                  font-weight: 500;
                  color: #ffffff;
                }
              }

              .tishi {
                margin-top: 20px;
                width: 280px;
                height: 36px;
                display: flex;
                align-items: center;

                img {
                  width: 20px;
                  height: 20px;

                }

                span {
                  width: 250px;

                  font-size: 12px;
                  margin-left: 10px;
                  font-family: Roboto-Regular, Roboto;
                  font-weight: 400;
                  color: #666666;
                  line-height: 18px;
                }
              }
            }
          }
        }

        width: $pageWidth;
        margin: 2px auto 100px;
        padding-top: 30px;

        h3 {
          font-size: 14px;

          font-weight: 400;
          color: #000000;
          margin: 0px 0 30px;
        }

        .left1 {
          transform: translateX(8px) !important;
          transition: all 1s;
        }

        .right1 {
          transform: translateX(-90px) !important;
          transition: all 1s;
        }

        .image {
          width: 480px;
          // overflow: hidden;
          margin-right: 60px;
          // border: solid 1px red;
          display: flex;
          flex-direction: column;

          .pic-box {
            width: 480px;
            height: 480px;
            display: inline-block;
            margin-right: 20px;
            margin-bottom: 20px;
            border-radius: 6px;
            border: 1px solid #e2e2e2;

            img {
              width: 480px;
              height: 480px;
            }
          }

          .bigVideo {
            width: 480px;
            height: 480px;
            display: inline-block;
            margin-right: 20px;
            margin-bottom: 20px;
            border-radius: 6px;
            border: 1px solid #e2e2e2;
          }

          // 切换图片
          .img-box {
            width: 480px;
            height: 104px;
            // background-color: pink;
            position: relative;

            // padding: 0 20px;
            .leftgo {
              width: 20px;
              height: 104px;
              cursor: pointer;
              display: flex;
              align-items: center;
              // background-color: #fff;
              position: absolute;
              z-index: 111;
              left: 0;
              margin-left: -20px;

              .icon {
                line-height: 104px;
                text-align: center;
                width: 25px;

                i {
                  font-size: 14px;
                  font-weight: 600;
                  color: #000;
                }
              }

              img {
                width: 100px;
                max-height: 100px;
              }
            }

            .rightgo {
              width: 20px;
              height: 104px;
              cursor: pointer;
              display: flex;
              // text-align: right;
              align-items: center;

              z-index: 111;
              margin-right: -20px;
              position: absolute;
              right: 0;

              .icon {
                line-height: 104px;
                text-align: center;
                width: 25px;

                i {
                  font-size: 14px;
                  font-weight: 600;
                  color: #000;
                }
              }

              img {
                width: 100px;
                max-height: 100px;
              }
            }

            .list {
              overflow: hidden;
              width: 100%;
              height: 100%;

              .images {
                overflow: hidden;
                height: 104px;
                width: 760px;
                display: flex;
                align-items: center;

                //  视频
                .video {
                  cursor: pointer;
                  position: relative;

                  .videoIcon {
                    position: absolute;
                    // background-color: rgba($color: #000000, $alpha: .3);
                    top: 0;
                    left: 0;
                    width: 100px;
                    height: 100px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    font-size: 40px;

                    .icon {
                      color: white;
                    }
                  }

                  video {
                    width: 100px;
                    height: 100px;
                    cursor: pointer;
                    border-radius: 4px;
                    border: 1px solid #e2e2e2;
                    margin-right: 15px;
                  }

                  .detailsImgIndex {
                    height: 100px;
                    border: 2px solid #ffa724;
                  }
                }

                .img {
                  width: 100px;
                  margin-right: 15px;

                  // margin-left: 10px;
                  img {
                    width: 100px;
                    height: 100px;
                    cursor: pointer;
                    border-radius: 4px;
                    border: 1px solid #e2e2e2;
                  }

                  .detailsImgIndex {
                    height: 100px;
                    border: 2px solid #ffa724;
                  }
                }
              }
            }

            .images {
              overflow: hidden;
              height: 104px;
              min-width: 640px;
              display: flex;
              align-items: center;

              .img {
                width: 100px;
                margin-right: 15px;

                // margin-left: 10px;
                img {
                  width: 100px;
                  height: 100px;
                  cursor: pointer;
                  border-radius: 4px;
                  border: 1px solid #e2e2e2;
                }

                .detailsImgIndex {
                  height: 100px;
                  border: 2px solid #ffa724;
                }
              }
            }
          }

          .huoyuan {
            margin: 20px 0 20px;
            padding-left: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            a {
              color: #333;
              margin-right: 40px;
              display: flex;
              align-items: center;

              .inco {
                width: 56px;
                height: 56px;
                background: #ffffff;
                border-radius: 28px;
                line-height: 56px;
                text-align: center;
                margin-right: 10px;

                img {
                  margin-right: 0;
                  width: 24px;
                  height: 24px;
                }
              }

              &:hover {
                color: #999;
              }
            }

            img {
              height: 18px;
              vertical-align: middle;
              margin-right: 5px;
            }

            p {
              margin: 10px 0;

              a {
                margin-right: 10px;
              }
            }

            .imgbox1688 {
              width: 208px;
              height: 100%;
              display: flex;
              align-items: center;

              span {
                display: flex;
                margin-right: 10px;

                font-weight: 400;
                font-size: 16px;
                color: #333333;
                line-height: 20px;
                text-align: left;
                font-style: normal;
              }

              a {
                margin-right: 0px;

                img {
                  width: 100px;
                  height: 20px;
                }
              }
            }
          }

          .text {
            width: 805px;

            h4 {
              font-size: 18px;

              font-weight: bold;
              color: #000000;
              margin-bottom: 35px;
            }

            .p {
              font-size: 16px;

              font-weight: 400;
              color: #ffa724;
              margin-bottom: 16px;
            }

            p {
              font-size: 14px;
            }

            .price-box {
              width: 100%;
              height: 120px;
              background: #fbfbfb;
              display: flex;
              box-sizing: border-box;
              padding: 30px 20px;
              margin-bottom: 20px;

              >div {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 150px;

                &:first-child {
                  width: unset;

                  >* {
                    font-size: 16px;

                    font-weight: 400;
                    color: #010101;
                    margin-right: 30px;
                  }
                }

                >* {
                  font-size: 14px;

                  font-weight: 400;
                  color: #ffa724;
                }

                .price {
                  font-size: 20px;
                  font-weight: bold;
                }
              }
            }

            .freightCharge {
              height: 16px;
              font-size: 16px;

              font-weight: 400;
              color: #000000;
              margin: 20px;
            }

            .addClassGroup {
              position: relative;
              top: 35px;
              margin-left: 20px;
              margin-top: -15px;

              button {
                width: 200px;
                height: 50px;
                background: #ffa724;
                border-radius: 0px;
                font-size: 18px;

                font-weight: 400;
                color: #ffffff;
                margin-right: 10px;

                &:last-child {
                  background: #ffffff;
                  border: 1px solid #ffa724;
                  border-radius: 0px;
                  font-size: 18px;

                  font-weight: 400;
                  color: #ffa724;
                }
              }
            }
          }
        }

        .temporaryListing {
          padding-top: 20px;
          overflow: unset;
          border-top: solid 1px #eeeeee;
          margin-bottom: -40px;
          position: relative;

          .down {
            width: 0;
            height: 0;
            border-width: 11.25px 11.25px 0;
            border-style: solid;
            border-color: #999 transparent transparent;
            /*灰 透明 透明 */
            position: absolute;
            bottom: 79.25px;
            left: 90px;

            img {
              position: absolute;
              transform: rotate(180deg) !important;
              width: 42px;
              height: 23px;
              top: -14px;
              left: -21px;
            }
          }

          h2 {
            margin-bottom: 38px;
            font-size: 18px;

            font-weight: bold;
            color: #000000;
          }

          ul {
            display: flex;
            text-align: center;
            align-items: center;
            height: 35px;
            border-top: #eeeeee solid 1px;
            border-bottom: #eeeeee solid 1px;

            &:first-child {
              border: none;
            }

            li {
              flex: 0.57;
              display: inline-block;

              button {
                width: unset;
                height: unset;
                background-color: transparent;
                color: black;
                margin: 0;
                cursor: pointer;
              }

              &:nth-child(1) {
                img {
                  width: 40px;
                  height: 40px;
                }
              }

              &:nth-child(2) {
                flex: 3.15;
                display: flex;
              }

              &:nth-child(3) {
                // margin-left: 699px;
                text-overflow: ellipsis;
              }
            }

            &.tableHead {
              background: #f6f6f6;
            }

            &.tableBody {
              height: 60px;
              overflow: hidden;

              .color {
                display: flex;
                max-width: 734px;
                align-items: center;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis; //文本溢出显示省略号
                padding-left: 13px;

                span {
                  margin-right: 15px;
                  line-height: 18px;

                  &:last-child {
                    margin-right: 0;
                  }
                }
              }
            }
          }

          button {
            margin-top: 40px;
            width: 360px;
            height: 50px;
            background: #ffa724;
            border-radius: 0px;
            color: white;
          }
        }
      }
    }

    .detail-box {
      overflow: hidden;
      margin-bottom: 50px;
      width: $pageWidth;
      margin: 40px auto 0;
      display: flex;
      background-color: #f6f6f6;

      .shop {
        width: $pageWidth;
        display: flex;
        $shopHeight: 330px;

        .intelligence {
          width: 260px;
          margin-right: 20px;
          height: $shopHeight;
          font-size: 16px;
          border-right: none;

          background-color: white;
          border-radius: 6px;
          border: 1px solid #e2e2e2;

          h4 {
            font-size: 16px;
            border-bottom: 1px solid #ccc;
            padding: 20px 0;
            text-align: center;
            // background-color: #eee;
          }

          p {
            text-align: center;
            margin: 30px 0;
            cursor: pointer;
          }

          button {
            display: block;
            margin: 0 auto 20px;
            width: 160px;
            height: 50px;
            border: solid 1px #ff730b;
            font-size: 14px;
            border-radius: 4px;
            font-weight: 400;
            color: #fff;
            background-color: #ff730b;

            &.toFavorite {
              background-color: #fff;
              color: black;
              border: solid 1px #e2e2e2;
            }
          }
        }

        .recommend {
          height: $shopHeight;
          border: 1px solid #e2e2e2;
          width: 100%;
          background-color: white;
          border-radius: 4px;

          h4 {
            font-size: 16px;
            padding: 20px 0;
            padding-left: 20px;
            text-align: left;
            border-bottom: 1px solid #ccc;
          }

          .recommendSlide {
            margin: 0 auto;

            .recommendSlideCon {
              background-color: white;

              padding-top: 20px;
              display: flex;

              .recommendSlideConOpt {
                width: 175px;
                height: 175px;
                margin: 0 28px;
                cursor: pointer;

                img {
                  width: 175px;
                  height: 175px;
                  margin-bottom: 5px;
                  border-radius: 4px;
                }

                .goodsTitle {
                  background-color: #fff;

                  p {
                    width: 180px;
                    font-size: 16px;
                    font-weight: 400;
                    color: #222;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    margin-bottom: 8px;

                    &:last-child {
                      margin-bottom: 0;
                    }

                    span {
                      font-weight: 600;
                    }
                  }
                }
              }
            }
          }

          ul {
            padding: 5px;

            li {
              border-bottom: 1px solid #000;
              padding-bottom: 10px;
              margin-top: 10px;
              overflow: hidden;

              img {
                width: 75px;
                height: 75px;
                margin-right: 5px;
                float: left;
              }

              .title {
                width: 120px;
                float: left;
                color: #000;
                font-size: 12px;

                p {
                  word-break: break-all;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                }

                span {
                  color: #ffa018;
                }
              }
            }
          }
        }
      }
    }

    .goodsDetail {
      margin: 40px auto 0px;
      background-color: white;
      width: $pageWidth;
      border-radius: 6px;
      border: 1px solid #e2e2e2;

      h4 {
        font-size: 18px;
        padding: 30px 0 30px 30px;
        font-weight: bold;
        border-bottom: solid 1px #e2e2e2;
      }

      >.property {
        display: flex;
        flex-wrap: wrap;
        margin: 30px;
        font-size: 14px !important;

        >p {
          width: 319px;
          margin-bottom: 20px;
          margin-right: 20px;

          &:nth-child(4n) {
            margin-right: 0;
          }

          &:last-child {
            margin: 0px;
          }

          >span {
            display: inline-block;
            width: 50%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

            &:first-child {
              font-weight: 400;
              color: #999999;
              width: 48%;
            }

            &:last-child {
              font-weight: 400;
              color: #333333;
              margin-right: 2%;
            }
          }
        }
      }

      >#detail {
        padding: 30px;
        text-align: center;
        overflow: hidden;
        // p {
        //   background-color: pink !important;
        //   img {
        //     position: relative !important;
        //   }
        // }
      }
    }

    #detail {
      /deep/ * {

        margin: auto;
      }
    }
  }

  .notHaveGoods {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 266px;
    background-color: white;
    width: 100%;

    .hintInfo {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      img {
        width: 480px;
        height: 480px;
        margin: 0 auto;
      }

      p {
        text-align: center;

        .backHomePage {
          padding: 10px 20px;
          margin-top: 40px;
          font-size: 16px;
          color: #ffffff;
          line-height: 18px;

          background: #ff730b;
          border-radius: 0px;
          cursor: pointer;
          border-radius: 4px;
          transition: 0.3s;

          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }
}

/deep/.el-carousel__container {
  i {
    color: #222;
    font-weight: 700;
    font-size: 25px;
  }
}

.el-input-number {
  width: 100px !important;
}

/deep/.el-input__inner {
  padding: 0;
}

/deep/ .el-input-number__decrease {
  left: 0;
}

/deep/ .el-input-number__increase {
  right: 0px !important;
}
</style>
